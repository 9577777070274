<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>About</v-card-title>
          <v-card-text> 
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex quisquam aperiam laborum nam nostrum, corrupti minima nemo tempora dolore, ullam possimus libero ipsum. Veniam, at iusto consequatur dolor expedita voluptates.</p>
          </v-card-text>
          <v-card-actions>
            <v-alert type="success">
              Salut
            </v-alert>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'About',
};
</script>
